import { lazy } from 'react'
import ModuleRoute from 'types/ModuleRoute'

const modules: ModuleRoute[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    menu: true,
    icon: 'dashboard',
    component: lazy(() => import('../modules/dashboard')),
    permission: 'dashboard_city',
    path: '/dashboard'
  },
  {
    id: 'collaborators',
    title: 'Colaboradores',
    menu: true,
    icon: 'group',
    component: lazy(() => import('../modules/collaborator')),
    permission: 'crud_employee',
    path: '/collaborators'
  },
  {
    id: 'news',
    title: 'Notícias',
    menu: true,
    icon: 'rss_feed',
    component: lazy(() => import('../modules/news')),
    permission: 'crud_news',
    path: '/news'
  }
]

export default modules
