import api from './api'
import Response from 'entities/Response'
import Profile from 'types/Profile'
import User from 'types/User'
import Pagination from 'types/Pagination'

export const listUsers = async (
  params?: Record<string, any>
): Promise<Response<Pagination<User>>> => {
  try {
    const response = await api.get('/users', { params })

    return Response.fromData<Pagination<User>>(response.data, response.status)
  } catch (e) {
    return Response.fromError(e)
  }
}

export const getRoles = async (): Promise<Response<Profile[]>> => {
  try {
    const response = await api.get('/roles/me')

    return Response.fromData<Profile[]>(
      response.data.roles.filter(
        (role) => role.key === 'city_admin'
      ) as Profile[],
      response.status
    )
  } catch (e) {
    return Response.fromError(e)
  }
}

export const setRole = async (
  roleId: string,
  companyId: string
): Promise<Response<string>> => {
  try {
    const response = await api.post('/user/role', {
      role: roleId,
      company: companyId
    })

    return Response.fromData<string>(response.data.token, response.status)
  } catch (e) {
    return Response.fromError(e)
  }
}

export const updateCurrentUser = async (
  user: Record<string, any>
): Promise<Response<User>> => {
  try {
    const response = await api.put('/users', user)

    return Response.fromData<User>(response.data.user as User, response.status)
  } catch (e) {
    return Response.fromError(e)
  }
}
